import { PiggyBank } from "@phosphor-icons/react";
import { useAppConfig } from "../hooks/useAppConfig";
import useCalculation from "../hooks/useCalculation";
import Button from "./Button";
import Modal from "./Modal";
import { useState } from "react";
import DealDetail from "./DealDetail";

const Totals = () => {
  const { color, link, title, currentDeal, compareDeal, cheapestDeal } =
    useAppConfig();
  const { difference, hasDeals, isNegative, totalCurrent, totalCompare } =
    useCalculation();
  const [activePopup, setActivePopup] = useState<boolean>(false);
  return (
    <>
      <div>
        <PiggyBank
          className={"w-8 h-8"}
          style={{
            color: color,
          }}
        />
      </div>
      <div className={"font-medium space-y-1"}>
        <p className={"leading-tight"}>
          {!hasDeals && "Vælg et elselskab for at se din besparelse"}
          {hasDeals && !isNegative && "Du kan spare"}
          {hasDeals && isNegative && "Vi er ikke billigere,"}
        </p>
        <p
          className={"text-6xl md:text-5xl font-semibold"}
          style={{
            color: color,
            opacity: hasDeals ? 1 : 0.25,
          }}
        >
          {!hasDeals && "0 kr"}
          {hasDeals && !isNegative && `${difference} kr`}
          {hasDeals && isNegative && `men...`}
        </p>
        {hasDeals && !isNegative && <p>om året</p>}
        {hasDeals && isNegative && <p>...vi har mange andre fordele.</p>}
      </div>
      <div>
        {hasDeals && (
          <button
            onClick={() => setActivePopup(true)}
            className={"underline underline-offset-4"}
          >
            Se specifikationer
          </button>
        )}
        <Modal active={activePopup} onClose={() => setActivePopup(false)}>
          <div className={"space-y-6"}>
            <h2
              className={"text-2xl md:text-3xl font-semibold"}
              style={{
                color: color,
              }}
            >
              Specifikationer
            </h2>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12"}>
              {compareDeal && <DealDetail deal={compareDeal} />}
              {currentDeal && <DealDetail deal={currentDeal} />}
            </div>
            <div className={"text-xs text-gray-500 mt-6"}>
              <p>
                Bemærk at priserne er eksklusiv de obligatorisker tariffer til
                transport og staten. Priserne er angivet inkl. moms.
              </p>
            </div>
          </div>
        </Modal>
        {link && (
          <div className={"md:hidden mt-6"}>
            <Button color={color} href={link} text={"Tilmeld nu"} />
          </div>
        )}
      </div>
    </>
  );
};

export default Totals;
