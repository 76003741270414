import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Widget from "./Widget";

const container = document.getElementById("price-compare-app");
if (container) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <Widget
        title={container?.dataset?.title}
        bgColor={container.dataset.bgColor}
        color={container.dataset.primaryColor}
        dealId={parseInt(container.dataset.dealId || "0")}
        compareDealId={parseInt(container.dataset.compareDealId || "0")}
        link={container.dataset.link}
        kwh={parseInt(container.dataset.kwh || "2000")}
      />
    </React.StrictMode>,
  );
}
