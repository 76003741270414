import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { useRef, useState } from "react";
import { useAppConfig } from "../hooks/useAppConfig";
import { CaretUpDown } from "@phosphor-icons/react";
import Spinner from "./Spinner";

interface ComboBoxData {
  value: number;
  label: string;
}

interface Props {
  data: ComboBoxData[];
  value: number | null;
  onChange: (value: number) => void;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
}

const ComboBoxInput = ({
  data = [],
  value,
  onChange,
  loading,
  placeholder,
  disabled = false,
}: Props) => {
  const [query, setQuery] = useState("");
  const ref = useRef<HTMLInputElement>(null);

  const filteredData =
    query === ""
      ? data
      : data.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div
      className={"flex items-center gap-x-1 cursor-pointer max-w-[250px]"}
      onClick={() => {
        ref.current?.focus();
      }}
    >
      {loading && (
        <div>
          <Spinner />
        </div>
      )}
      {!loading && (
        <Combobox
          immediate
          value={value}
          onChange={onChange}
          onClose={() => setQuery("")}
        >
          <CaretUpDown
            className={`w-4 h-4 sm:hidden ${disabled && "opacity-50"}`}
            weight={"bold"}
          />
          <ComboboxInput
            disabled={disabled}
            ref={ref}
            className={
              "bg-transparent inline-flex w-auto sm:text-right p-0 border-none focus:ring-0 focus:outline-none placeholder:opacity-50 disabled:opacity-50"
            }
            placeholder={placeholder}
            aria-label={placeholder}
            displayValue={() =>
              data.find((item) => item.value === value)?.label || ""
            }
            onChange={(event) => setQuery(event.target.value)}
          />
          <CaretUpDown className={"w-4 h-4 hidden sm:block"} weight={"bold"} />
          <ComboboxOptions
            anchor={{ to: "top end", gap: "4px", padding: "100px" }}
            className="[--anchor-gap:4px] z-50 my-1 w-52 mb-1 bg-white border border-gray-300 rounded-md shadow-lg"
          >
            {filteredData.map((item) => (
              <ComboboxOption
                key={item.value}
                value={item.value}
                className="data-[focus]:bg-gray-100 bg-white rounded-lg transparent cursor-pointer text-sm px-3 py-2 hover:bg-gray-100"
              >
                {item.label}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      )}
    </div>
  );
};

export default ComboBoxInput;
