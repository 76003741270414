import AppConfigProvider from "./hooks/useAppConfig";
import App from "./components/App";

interface Props {
  title?: string;
  bgColor?: string;
  color?: string;
  dealId?: number;
  compareDealId?: number;
  link?: string;
  kwh?: number;
}

const Widget = ({
  title,
  bgColor,
  color,
  dealId,
  link,
  kwh,
  compareDealId,
}: Props) => {
  return (
    <AppConfigProvider
      initialProps={{
        title,
        bgColor,
        color,
        dealId,
        compareDealId,
        link,
        kwh,
      }}
    >
      <App />
    </AppConfigProvider>
  );
};

export default Widget;
